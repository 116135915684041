import React, { useEffect, useState, useContext, Fragment } from "react";
import Sidebar from "../../components/layouts/Sidebar";
import axios from "axios";
import { UserContext } from "../../context";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../components/layouts/Footer";
import "./admin.css";
import UserList from "../../components/admin/userlist/UserList";

const Users = () => {
  const [state, setState] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [subscribedUsers, setSubscribedUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get("/admin/users");
        if (data) {
          setUsers(data); // Update state with users data
          calculateCounts(data); // Calculate counts based on fetched data
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const calculateCounts = (users) => {
    const total = users.length;
    const subscribed = users.filter(user => user.subscription && user.subscription.status === "active").length;
    const inactive = users.filter(user => user.subscription && user.subscription.status !== "active").length;

    setTotalUsers(total);
    setSubscribedUsers(subscribed);
    setInactiveUsers(inactive);
  };

  return (
    <Fragment>
      <Sidebar />
      <div className="row">
        <main className={`col-lg-8 mx-auto mt-5 container`}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="spinner-border custom-spin" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="container mt-5 mb-5">
              <div className="mt-5 mb-5">
                <h3 className="text-center mt-5 mb-5">All Users Data</h3>
                <div className="row row-cols-1 row-cols-md-4 g-2 mt-5 mb-5">
                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-delete text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-clock-history fs-1 mb-3"></i>
                        <h5 className="card-title">Total Users</h5>
                        <p className="card-text">{totalUsers}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-pending text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-clock-history fs-1 mb-3"></i>
                        <h5 className="card-title">Subscribed Users</h5>
                        <p className="card-text">{subscribedUsers}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-scheduled text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-alarm fs-1 mb-3"></i>
                        <h5 className="card-title">Inactive Users</h5>
                        <p className="card-text">{inactiveUsers}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <UserList />
        </main>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Users;
