import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  List,
  Space,
  Spin,
  Modal,
  Button,
  Input,
  Select,
  Form,
  Row,
  Col,
} from "antd";
import { EyeOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { UserContext } from "../../../context";

const { Option } = Select;

const UserList = () => {
  const [state] = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [viewUserModalVisible, setViewUserModalVisible] = useState(false);
  const [editRoleModalVisible, setEditRoleModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [form] = Form.useForm();

//   Live Plan code to plan name mapping
  const planCodeToName = {
    PLN_iwmm2cbvp6vh1r5: "Runti Basic Plan",
    PLN_l24au1zx0v0uefb: "Runti Standard Plan",
    PLN_i8w3ub0yuoa3nec: "Runti Premium Plan",
  };

//   // Test Plan code to plan name mapping
//   const planCodeToName = {
//     PLN_2utrc5dppn08ooc: "Basic Plan",
//     PLN_3zb30vwzfdj5k08: "Standard Plan",
//     PLN_y59hn2n7x2oge7n: "Premium Plan",
//   };

  const showViewUserModal = (user) => {
    setSelectedUser(user);
    setViewUserModalVisible(true);
  };

  const showEditRoleModal = (user) => {
    setSelectedUser(user);
    form.setFieldsValue({ role: user.role });
    setEditRoleModalVisible(true);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get("/admin/users");
        if (data) {
          setUsers(data); // Update state with users data
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleEditRole = async () => {
    try {
      const values = await form.validateFields();
      await axios.put("/admin/update-role", {
        userId: selectedUser._id,
        role: values.role,
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === selectedUser._id ? { ...user, role: values.role } : user
        )
      );
      setEditRoleModalVisible(false);
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div className="container mt-5 mb-5 px-4 py-4">
      <List
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 5,
        }}
        dataSource={users}
        renderItem={(item, index) => (
          <List.Item
            className="shadow mt-4 mb-4 px-4"
            key={item._id}
            actions={[
              <EyeOutlined
                key="view"
                onClick={() => showViewUserModal(item)}
              />,
              <UserOutlined
                key="edit"
                onClick={() => showEditRoleModal(item)}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={item.avatar.url} />}
              title={item.name}
              description={
                <>
                  Email: {item.email}
                  <br />
                  Role: {item.role}
                  <br />
                  Subscription Plan:{" "}
                  {planCodeToName[item.subscription?.plan?.plan_code] || "None"}
                </>
              }
            />
          </List.Item>
        )}
      />

      <Modal
        title="User Details"
        visible={viewUserModalVisible}
        onCancel={() => setViewUserModalVisible(false)}
        footer={null}
      >
        {selectedUser && (
          <Form labelCol={{ span: 24 }}>
            <Row gutter={[6, 6]}>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ fontSize: "12px" }}>User Name</span>}
                  className="form-col"
                >
                  <Input value={selectedUser.name} readOnly />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontSize: "12px" }}>Email Address</span>
                  }
                  className="form-col"
                >
                  <Input value={selectedUser.email} readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[6, 6]}>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ fontSize: "12px" }}>Role</span>}
                  className="form-col"
                >
                  <Input value={selectedUser.role} readOnly />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontSize: "12px" }}>
                      Subscription Status
                    </span>
                  }
                  className="form-col"
                >
                  <Input
                    value={
                      planCodeToName[
                        selectedUser.subscription?.plan?.plan_code
                      ] || "None"
                    }
                    readOnly
                  />
                </Form.Item>
              </Col>
            </Row>

            {selectedUser.subscription && (
              <Row gutter={[6, 6]}>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ fontSize: "12px" }}>Amount</span>}
                    className="form-col"
                  >
                    <Input
                      value={(
                        selectedUser.subscription.plan.amount / 100
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: selectedUser.subscription.plan.currency,
                      })}
                      readOnly
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "12px" }}>
                        Subscription Status
                      </span>
                    }
                    className="form-col"
                  >
                    <Input
                      value={selectedUser.subscription.status}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Modal>

      <Modal
        title="Edit User Role"
        visible={editRoleModalVisible}
        onCancel={() => setEditRoleModalVisible(false)}
        onOk={handleEditRole}
      >
        <Form form={form}>
          <Form.Item name="role" label="Role" rules={[{ required: true }]}>
            <Select>
              <Option value="User">User</Option>
              <Option value="Admin">Admin</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserList;
