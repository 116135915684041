import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import AdminRoute from "./components/routes/AdminRoute";
import Login from "./pages/auth/Login";
import AdminDashboard from "./pages/admin/AdminDashboard";
import Users from "./pages/admin/Users";
import VendorList from "./pages/admin/VendorList";
import Settings from "./pages/admin/Settings";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
        <AdminRoute exact path="/admin/users" component={Users} />
        <AdminRoute exact path="/admin/vendors" component={VendorList} />
        <AdminRoute exact path="/admin/settings" component={Settings} />
      </Switch>
    </Router>
  );
}

export default App;
