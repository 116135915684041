import React, { useState } from "react";


const Input = ({ label, value, setValue, type = "text" }) => {
  const [visible, setVisible] = useState(false);
  const inputId = `${label.toLowerCase()}-input`;

  return (
    <div className={`input-group mb-3  ${type === "password" ? "rounded" : ""}`}>
      <span className="input-group-text">{label}</span>
      <input
        id={inputId}
        type={visible ? "text" : type}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        className={`form-control ${type === "password" ? "rounded-end" : ""}`}
      />
      {type === "password" && (
        <span className="input-group-text border-0">
          {visible ? (
            <i
              className="bi bi-eye-fill"
              style={{ cursor: "pointer" }}
              onClick={() => setVisible(!visible)}
            ></i>
          ) : (
            <i
              className="bi bi-eye-slash-fill"
              style={{ cursor: "pointer" }}
              onClick={() => setVisible(!visible)}
            ></i>
          )}
        </span>
      )}
    </div>
  );
};

export default Input;