import React from "react";

const Button = ({
  type = "primary",
  size = "md",
  text = "Submit",
  isLoading = false,
  handleClick,
  className, 
}) => (
  <button
    onClick={handleClick}
    className={`btn btn-${type} btn-${size} ${className}`}
    disabled={isLoading}
  >
    {isLoading ? (
      <div className="spinner-border spinner-border-sm me-2" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    ) : (
      text
    )}
  </button>
);

export default Button;
