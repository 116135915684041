import React, { useEffect, useState, useContext, Fragment } from "react";
import Sidebar from "../../components/layouts/Sidebar";
import axios from "axios";
import { UserContext } from "../../context";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../components/layouts/Footer";
import "./admin.css";
import ReminderList from "../../components/admin/reminderlist/ReminderList";

const AdminDashboard = () => {
  const [totals, setTotals] = useState({});
  const [state, setState] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reminders, setReminders] = useState([]);

  useEffect(() => {
    fetchReminders();
  }, []);

  const fetchReminders = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get("/admin/reminders/count");
      const {
        reminders,
        totalCreatedReminders,
        totalScheduledReminders,
        totalDeletedReminders,
        totalUpdatedReminders,
      } = response.data;

      setReminders(reminders);
      setTotals({
        totalCreatedReminders,
        totalScheduledReminders,
        totalDeletedReminders,
        totalUpdatedReminders,
      });
      setIsLoading(false); // Stop loading
    } catch (error) {
      console.error("Error fetching reminders:", error);
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <Fragment>
      <Sidebar />
      <div className="row">
        <main className={`col-lg-8 mx-auto mt-5 container`}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="spinner-border custom-spin" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="container mt-5 mb-5">
              <div className="mt-5 mb-5">
                <h3 className="text-center mt-5 mb-5">
                  All Users Reminder Data
                </h3>
                <div className="row row-cols-1 row-cols-md-4 g-2 mt-5 mb-5">
                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-delete text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-calendar-check fs-1 mb-3"></i>
                        <h5 className="card-title">Total Reminders</h5>
                        <p className="card-text">{reminders.length}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-pending text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-clock-history fs-1 mb-3"></i>
                        <h5 className="card-title">Pending Reminders</h5>
                        <p className="card-text">
                          {totals.totalCreatedReminders}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-scheduled text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-alarm fs-1 mb-3"></i>
                        <h5 className="card-title">Scheduled Reminders</h5>
                        <p className="card-text">
                          {totals.totalScheduledReminders}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ReminderList />
        </main>
      </div>

      <Footer />
    </Fragment>
  );
};

export default AdminDashboard;
