import React, { useContext, useState } from "react";
import "./auth.css";
import { UserContext } from "../../context";
import axios from "axios";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import SuccessAlert from "../../components/alerts/SuccessAlert";
import Button from "../../components/Button.js";
import Input from "../../components/Input.js";
import { Link } from "react-router-dom"; // Use Link from react-router-dom

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [state, setState] = useContext(UserContext);
  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      const { data } = await axios.post("/admin/login", { email, password });

      if (data.error) {
        setErrorAlert(<ErrorAlert message={data.error} />);
      } else {
        setEmail("");
        setPassword("");
        setSuccessAlert(<SuccessAlert message="Login successful!" />);
        localStorage.setItem("auth", JSON.stringify(data));
        setState(data);
        setTimeout(() => {
          history.push("/admin/dashboard");
        }, 1000);
      }
    } catch (err) {
      setErrorAlert(<ErrorAlert message="Something went wrong. Try again" />);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid bg-body-auth height-100vh d-flex align-items-center justify-content-center">
      <div className="login-wrapper">
        <div className="text-center mb-4">
          <Link className="navbar-brand" to="/">
            <img
              src="/assets/images/runtinewlogo.png"
              alt="Logo"
              width="100px"
              height="100px"
            />
          </Link>
          <h1 className="display-4 fw-bold lh-1 text-body-emphasis auth-header-text">Admin Login</h1>
        </div>
        <form className="p-4 p-md-5 border rounded-3 bg-body-auth shadow">
          <div className="form-group">
            {errorAlert}
            {successAlert}
            <Input label="Email" type="email" value={email} setValue={setEmail} />
            <Input label="Password" type="password" value={password} setValue={setPassword} />
            <div className="d-grid auth-button-container mt-3 mb-3">
              <Button handleClick={handleClick} type="primary" text="Login" size="sm" isLoading={isLoading} className="auth-button" />
            </div>
          </div>
          <div className="checkbox mb-3 text-center">
            <label>
              <input type="checkbox" defaultValue="remember-me" /> Remember me
            </label>
          </div>
          <hr className="my-4" />
        </form>
      </div>
    </div>
  );
};

export default Login;
