import React, { useEffect, useState, useContext, Fragment } from "react";
import Sidebar from "../../components/layouts/Sidebar";
import axios from "axios";
import { UserContext } from "../../context";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../components/layouts/Footer";
import {
  Modal,
  Button,
  Form,
  Input,
  notification,
  Row,
  Col,
  Card,
  Avatar,
  List,
  Tooltip,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  TruckOutlined,
} from "@ant-design/icons";
import "./admin.css";

const VendorList = () => {
  const [state, setState] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [deliveryFees, setDeliveryFees] = useState([]);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedFee, setSelectedFee] = useState(null);
  const [vendorProductsCount, setVendorProductsCount] = useState(0);
  const [vendorOrdersCount, setVendorOrdersCount] = useState(0);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const vendorsResponse = await axios.get("/admin/vendors");
        const deliveryFeesResponse = await axios.get("/delivery-fees");
        const ordersResponse = await axios.get("/orders");
        const productsResponse = await axios.get("/products");

        setVendors(vendorsResponse.data);
        setDeliveryFees(deliveryFeesResponse.data);
        setOrders(ordersResponse.data);
        setProducts(productsResponse.data);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, []);

  const fetchVendorDetails = async (vendorId) => {
    try {
      const productCountResponse = await axios.get(`/products/vendor/${vendorId}/count`);
      console.log(productCountResponse)
      const orderCountResponse = await axios.get(`/orders/vendor/${vendorId}/count`);
      console.log(orderCountResponse)
      
      setVendorProductsCount(productCountResponse.data.productCount);
      setVendorOrdersCount(orderCountResponse.data.orderCount);
    } catch (error) {
      console.error("Failed to fetch vendor details", error);
    }
  };

  const handleViewVendorDetails = (vendor) => {
    setSelectedVendor(vendor);
    fetchVendorDetails(vendor._id);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await axios.post("/delivery-fee", values);
      notification.success({
        message: "Delivery Fee Created",
        description: "The delivery fee has been created successfully.",
      });
      form.resetFields();
      setIsModalVisible(false);
      // Refresh delivery fees
      const deliveryFeesResponse = await axios.get("/delivery-fees");
      setDeliveryFees(deliveryFeesResponse.data);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to create delivery fee.",
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showEditModal = (fee) => {
    setSelectedFee(fee);
    editForm.setFieldsValue(fee);
    setIsEditModalVisible(true);
  };

  const handleEditOk = async () => {
    try {
      const values = await editForm.validateFields();
      await axios.put(`/delivery-fee/${selectedFee._id}`, values);
      notification.success({
        message: "Delivery Fee Updated",
        description: "The delivery fee has been updated successfully.",
      });
      setIsEditModalVisible(false);
      // Refresh delivery fees
      const deliveryFeesResponse = await axios.get("/delivery-fees");
      setDeliveryFees(deliveryFeesResponse.data);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to update delivery fee.",
      });
    }
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/delivery-fee/${id}`);
      notification.success({
        message: "Delivery Fee Deleted",
        description: "The delivery fee has been deleted successfully.",
      });
      // Refresh delivery fees
      const deliveryFeesResponse = await axios.get("/delivery-fees");
      setDeliveryFees(deliveryFeesResponse.data);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to delete delivery fee.",
      });
    }
  };

  return (
    <Fragment>
      <Sidebar />
      <div className="row">
        <main className={`col-8 mx-auto mt-5 container`}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="spinner-border custom-spin" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="container mt-5 mb-5">
              <div className="mt-5 mb-5">
                <h3 className="text-center mt-5 mb-5">All Vendors Data</h3>
                <div className="row row-cols-1 row-col-lg-6 g-2 mt-5 mb-5">
                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-delete text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-clock-history fs-1 mb-3"></i>
                        <h5 className="card-title">Total Vendors</h5>
                        <p className="card-text">{vendors.length}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-pending text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-clock-history fs-1 mb-3"></i>
                        <h5 className="card-title">Vendors Orders</h5>
                        <p className="card-text">{orders.length}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-pending-orders text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-alarm fs-1 mb-3"></i>
                        <h5 className="card-title">Pending Vendors Orders</h5>
                        <p className="card-text">
                          {
                            orders.filter((order) => order.status === "pending")
                              .length
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-scheduled text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-alarm fs-1 mb-3"></i>
                        <h5 className="card-title">Delivered Orders</h5>
                        <p className="card-text">
                          {
                            orders.filter(
                              (order) => order.status === "delivered"
                            ).length
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="card h-100 bg-scheduled text-white shadow">
                      <div className="card-body d-flex flex-column justify-content-center">
                        <i className="bi bi-alarm fs-1 mb-3"></i>
                        <h5 className="card-title">Total Vendors Products</h5>
                        <p className="card-text">{products.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Row gutter={16}>
                <Col span={12}>
                  <h3 className="text-center mt-5 mb-5">Vendors</h3>
                  <List
                    itemLayout="horizontal"
                    dataSource={vendors}
                    renderItem={(vendor) => (
                      <List.Item
                        actions={[
                          <Tooltip title="View Details">
                            <EyeOutlined
                              onClick={() => setSelectedVendor(vendor)}
                            />
                          </Tooltip>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={<Avatar src={vendor.avatar?.url} />}
                          title={vendor.shopName}
                          description={`Role: ${
                            vendor.role
                          } | Created At: ${moment(vendor.createdAt).format(
                            "YYYY-MM-DD"
                          )}`}
                        />
                      </List.Item>
                    )}
                  />
                  <Modal
                    title="Vendor Details"
                    visible={!!selectedVendor}
                    footer={null}
                    onCancel={() => setSelectedVendor(null)}
                  >
                    {selectedVendor && (
                      <div>
                        <p>Name: {selectedVendor.name}</p>
                        <p>Email: {selectedVendor.email}</p>
                        <p>Shop Address: {selectedVendor.shopAddress}</p>
                        <p>Phone Number: {selectedVendor.phoneNumber}</p>
                        <p>Orders: {handleViewVendorDetails.vendorProductsCount}</p>
                        <p>Products: {handleViewVendorDetails.vendorOrdersCount}</p>
                        <p>
                          Created At:{" "}
                          {moment(selectedVendor.createdAt).format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </div>
                    )}
                  </Modal>
                </Col>
                <Col span={12}>
                  <h3 className="text-center mt-5 mb-5">Delivery Fees</h3>
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      type="primary"
                      icon={<TruckOutlined />}
                      onClick={showModal}
                    >
                      Create Delivery Fee
                    </Button>
                  </div>
                  <List
                    grid={{ gutter: 16, column: 1 }}
                    dataSource={deliveryFees}
                    renderItem={(fee) => (
                      <List.Item>
                        <Card
                          actions={[
                            <Tooltip title="Edit">
                              <EditOutlined
                                onClick={() => showEditModal(fee)}
                              />
                            </Tooltip>,
                            <Tooltip title="Delete">
                              <DeleteOutlined
                                onClick={() => handleDelete(fee._id)}
                              />
                            </Tooltip>,
                          ]}
                        >
                          <Card.Meta title={`${fee.state}: ₦${fee.fee}`} />
                        </Card>
                      </List.Item>
                    )}
                  />
                  <Modal
                    title="Create Delivery Fee"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <Form form={form} layout="vertical">
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "Please input the state!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="fee"
                        label="Fee"
                        rules={[
                          { required: true, message: "Please input the fee!" },
                        ]}
                      >
                        <Input type="number" />
                      </Form.Item>
                    </Form>
                  </Modal>
                  <Modal
                    title="Edit Delivery Fee"
                    visible={isEditModalVisible}
                    onOk={handleEditOk}
                    onCancel={handleEditCancel}
                  >
                    <Form form={editForm} layout="vertical">
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "Please input the state!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="fee"
                        label="Fee"
                        rules={[
                          { required: true, message: "Please input the fee!" },
                        ]}
                      >
                        <Input type="number" />
                      </Form.Item>
                    </Form>
                  </Modal>
                </Col>
              </Row>
            </div>
          )}
        </main>
      </div>
      <Footer />
    </Fragment>
  );
};

export default VendorList;
