import React, { useState } from 'react';

const SuccessAlert = ({ message }) => {
  const [dismissed, setDismissed] = useState(false);

  return (
    !dismissed && (
      <div className="alert alert-success alert-dismissible fade show" role="alert">
        <i className="bi bi-check-circle-fill me-2"></i>
        {message}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={() => setDismissed(true)}
        ></button>
      </div>
    )
  );
}

  
  export default SuccessAlert;