import React, { useEffect, useState, useContext } from "react";
import { LikeOutlined, MessageOutlined, StarOutlined } from "@ant-design/icons";
import { Avatar, Image, List, Space, Spin } from "antd";
import axios from "axios";
import { UserContext } from "../../../context";
import { Link } from "react-router-dom";
import moment from "moment";

// Live Plan code to plan name mapping
const planCodeToName = {
  PLN_iwmm2cbvp6vh1r5: "Runti Basic Plan",
  PLN_l24au1zx0v0uefb: "Runti Standard Plan",
  PLN_i8w3ub0yuoa3nec: "Runti Premium Plan",
};


// Test Plan code to plan name mapping
// const planCodeToName = {
//   PLN_2utrc5dppn08ooc: "Basic Plan",
//   PLN_3zb30vwzfdj5k08: "Standard Plan",
//   PLN_y59hn2n7x2oge7n: "Premium Plan",
// };

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const getIconForType = (type) => {
  switch (type) {
    case "birthday":
      return (
        <>
          Birthday Celebration: <i className="bi bi-cake"></i>
        </>
      );
    case "wedding day":
      return (
        <>
          Wedding Celebration: <i className="bi bi-ballon-heart"></i>
        </>
      );
    case "anniversary":
      return (
        <>
          Anniversary Celebration: <i className="bi bi-award-fill"></i>
        </>
      );
    case "graduation":
      return (
        <>
          Graduation Celebration: <i className="bi bi-mortarboard"></i>
        </>
      );
    default:
      return (
        <>
          Others: <i className="bi bi-gift"></i>
        </>
      );
  }
};

const ReminderList = () => {
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state] = useContext(UserContext);

  useEffect(() => {
    const fetchReminders = async () => {
      try {
        const { data } = await axios.post("/admin/reminders", { filters: {} });
        if (data.success) {
          setReminders(data.data);
        }
      } catch (error) {
        console.error("Error fetching reminders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReminders();
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <div className="container mt-5 mb-5 px-4 py-4">
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 3,
        }}
        dataSource={reminders}
        footer={
          <div>
            <b>Reminders</b>
          </div>
        }
        renderItem={(item) => (
          <List.Item
            className="shadow mt-4 mb-4"
            key={item._id}
            actions={[
              <span key="list-vertical-type-icon">
                {getIconForType(item.type)}
              </span>,
            ]}
            extra={<Image width={272} alt="reminder" src={item.image.url} />}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  src={state?.user?.avatar?.url || "default-avatar-url"}
                />
              }
              title={<>Celebrant Name: {item.celebrant}</>}
              description={
                <>
                  Created by: {item.userId?.name || "Unknown"} on{" "}
                  {new Date(item.createdAt).toLocaleDateString()}
                  <br />
                  Set Reminder Time: {moment(item.datetime).format("LLL")}
                  <br />
                  Celebrant Special Date:{" "}
                  {new Date(item.dateOfBirth).toLocaleDateString()}
                  <br />
                  Message/Wishes: {item.message}
                </>
              }
            />
            <div>
              Subscription Plan:{" "}
              {planCodeToName[item.subscription?.plan?.plan_code] || "None"}
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ReminderList;
